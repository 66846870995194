import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from './components/core/Root';
import './assets/sass/app.scss';


const rootElement = document.getElementById('root');

const root = ReactDOM.createRoot(rootElement);

root.render( 
    <Root/>
);
