import {lazy, Suspense} from 'react';
import {Routes, Route} from 'react-router-dom';
import routes from '../../config/routes';

const Home = lazy(() => import('../pages/Home'));

const App = () => {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path={routes.home} element={<Home/>}/>
      </Routes>
    </Suspense>
  );
}

export default App;
